<template>
  <div class="flex justify-center bg-[#FEFAF1] md:pt-8">
    <div class="w-full px-[24px] md:px-[60px] md:w-[95vw]">
      <div class="mb-8">
        <h2 class="mb-4 text-center text-[14px] font-normal text-[#011B33] justify-center">
          Trusted by businesses and partners spanning Fintech, Healthtech, Logistics, Insurtech, <br />
          Retail, Beauty, Autotech, Edutech, Fashion, Media etc.
        </h2>

        <ScrollingLogos :logos="companyLogos" />
      </div>
    </div>
  </div>
</template>

<script setup>
import ScrollingLogos from '../common/ScrollingLogos.vue';
import remotePassLogo from '../../assets/images/remotepassLogo.svg';
import piggyVestLogo from '../../assets/images/piggyvestLogo.svg';
import moniePointLogo from '../../assets/images/moniepointLogo.svg';
import flutterwaveLogo from '../../assets/images/flutterwaveLogo.svg';
import autocheckLogo from '../../assets/images/autochek.png';
import bambooLogo from '../../assets/images/bamboo.png';
import codematicLogo from '../../assets/images/codematic.png';
import sabiLogo from '../../assets/images/sabi.png';
import techcabal from '../../assets/images/techcabal.png';

const companyLogos = [
  { src: remotePassLogo, alt: 'RemotePass' },
  { src: piggyVestLogo, alt: 'PiggyVest' },
  { src: moniePointLogo, alt: 'MoniePoint' },
  { src: flutterwaveLogo, alt: 'Flutterwave' },
  { src: autocheckLogo, alt: 'Autocheck' },
  { src: bambooLogo, alt: 'Bamboo' },
  { src: codematicLogo, alt: 'Codematic' },
  { src: sabiLogo, alt: 'Sabi' },
  { src: techcabal, alt: 'TechCabal' },
];

const props = defineProps({
  heroImg: { type: String },
  imageContainerClass: String,
  imageClass: { type: String },
});
</script>

<style scoped>
.animate-scroll {
  animation: scroll 40s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll:hover {
  animation-play-state: paused;
}
</style>
